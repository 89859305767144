import { any } from "prop-types";
import { ActionExtended } from "src/_common/interfaces/ActionExtended";
import { ACTIONS } from "src/_config";
export interface TeamReducer {
  tempTeamDraftList: any;
  eventList: any;
  insuranceList: any;
  prizeList: any;
  silverLeague: any;
  contestantAIList: any;
  AISelectList: any;
  constList: any;
  teamTemporaryData: any;
  teamList: any;
  leagueList: any;
  curGameList: any;
  isSearchTeamPage: any;
  loadedTeam: any;
  paymentPlans: any;
  performanceList: any;
  cowboyInsurance: any;
  rankdetails: any;
  cartItems: any;
  loadedLeague: any;
}

const initialState: TeamReducer = {
  tempTeamDraftList: null,
  eventList: null,
  insuranceList: null,
  prizeList: null,
  silverLeague: null,
  contestantAIList: null,
  AISelectList: [],
  constList: null,
  teamTemporaryData: null,
  teamList: null,
  leagueList: null,
  curGameList: null,
  isSearchTeamPage: null,
  loadedTeam: null,
  paymentPlans: null,
  performanceList: null,
  cowboyInsurance: null,
  rankdetails: null,
  cartItems: null,
  loadedLeague: null,
};

const teamReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.TEAM.LOAD_DRAFT_TEAM_DATA:
      return {
        ...state,
        tempTeamDraftList: action.payload,
      };
    case ACTIONS.TEAM.GET_EVENT_LIST:
      return {
        ...state,
        eventList: action.payload,
      };
    case ACTIONS.TEAM.GET_INSURANCE_LIST:
      return {
        ...state,
        insuranceList: action.payload.data,
      };
    case ACTIONS.TEAM.GET_PRIZE_LIST:
      return {
        ...state,
        prizeList: action.payload.data,
      };
    case ACTIONS.TEAM.GET_FRONTEND_LEAGUE:
      return {
        ...state,
        silverLeague: action.payload.data,
      };
    case ACTIONS.LEAGUE.LOADEDLEAGUE:
      return {
        ...state,
        loadedLeague: action.payload.data,
      };
    case ACTIONS.LEAGUE.CLEARLOADEDLEAGUE:
      return {
        ...state,
        loadedLeague: null,
      };
    case ACTIONS.TEAM.GET_CONTESTANT_AI:
      return {
        ...state,
        contestantAIList: action.payload,
      };
    case ACTIONS.TEAM.SET_AI_SELECT_LIST:
      return {
        ...state,
        AISelectList: action.payload,
      };
    case ACTIONS.TEAM.CLEAR_AI:
      return {
        ...state,
        AISelectList: [],
        constList: [],
      };
    case ACTIONS.TEAM.CLEAR_CONST_AI:
      return {
        ...state,
        constList: [],
      };
    case ACTIONS.TEAM.CLEAR_RESET_TEAM:
      return {
        ...state,
        teamTemporaryData: null,
        // tempTeamDraftList: null,
      };
    case ACTIONS.TEAM.MY_TEAM_CLEAR_LIST:
      return {
        ...state,
        teamList: [],
      };
    case ACTIONS.TEAM.SAVE_TEMPORARY_DATA:
      return {
        ...state,
        teamTemporaryData: action.payload,
      };
    case ACTIONS.TEAM.SEARCH_AI_SELECT_LIST:
      let constList: any = [];
      const data = action.payload;
      if (data && data.list) {
        data.list.forEach((v: any, i: number) => {
          let myObj = {
            key: v.id,
            value: v.name + "(" + v.event_info.title + ")",
            salary: v.salary.salary_cap,
            eventId: v.event_info.id,
            eventName: v.event_info.title,
            contestantName: v.name.toUpperCase(),
            isInjured: v.is_injured ? 1 : 0,
            scratched: v.status,
          };
          constList.push(myObj);
        });
      }
      return {
        ...state,
        constList: constList,
      };
    case ACTIONS.TEAM.UPDATE_CONST_LIST_AI:
      const val = action.payload;
      let AISelectListData = state.AISelectList ? state.AISelectList : [];
      AISelectListData.push(val);
      return {
        ...state,
        AISelectList: AISelectListData,
      };
    case ACTIONS.TEAM.MY_TEAM_LIST:
      let teamList: any[] = [];
      if (action.payload) {
        action.payload.my_teams.forEach((v: any, i: number) => {
          let myObj = {
            id: v.id,
            tname: v.team_name,
            slug: v.team_slug,
            gname: v.game_info.title,
            glogo: v.game_info.avatar.original,
            is_entry_closed: v.game_info.gm_settings.is_entry_closed,
            spent: v.total_spent,
            created: v.name,
            otherStatus: v.miscellaneous_status,
            status: v.status,
            is_edit_team: v.is_edit_team,
            cboyInfo: v.cboy_info,
            game_last_performance: v.game_last_performance,
          };
          teamList.push(myObj);
        });
      }
      return {
        ...state,
        teamList: teamList,
        curGameList: action.payload.games,
      };
    case ACTIONS.LEAGUE.MY_LEAGUE_LIST:
      let leagueList: any[] = [];
      if (action.payload) {
        action.payload.my_leagues.forEach((v: any, i: number) => {
          let myObj = {
            id: v.id,
            name: v.league_name,
            code: v.league_code,
            gImage: v.game_info.avatar.original,
            gname: v.game_info.title,
            encrypt_id: v.encrypt_id,
            tname: v.top_team ? v.top_team.team_name : null,
            ttotal: v.top_team ? v.top_team.total : null,
            userId: v.user_id,
            noOfTeamsAssociate: v.no_of_teams_associate,
          };
          leagueList.push(myObj);
        });
      }
      return {
        ...state,
        leagueList: leagueList,
      };
    case ACTIONS.TEAM.IS_SEARCH_TEAM_PAGE:
      return {
        ...state,
        isSearchTeamPage: action.payload,
      };
    case ACTIONS.TEAM.LOAD_TEAM_DETAILS:
      return {
        ...state,
        loadedTeam: action.payload.data,
        paymentPlans: action.payload.payment_plans,
      };
    case ACTIONS.TEAM.GET_PERFORMANCE_LIST:
      return {
        ...state,
        performanceList: action.payload.data,
      };
    case ACTIONS.SCORE.GET_SCORE_DETAILS:
      let teamData = Object.assign({}, state.loadedTeam);
      teamData.score_list = action.payload;
      return {
        ...state,
        loadedTeam: teamData,
      };
    case ACTIONS.TEAM.GET_INSURANCE_APPLICABLE:
      return {
        ...state,
        cowboyInsurance: action.payload,
      };
    case ACTIONS.TEAM.INSURANCE_APPLY:
      let loadedTeam = Object.assign({}, state.loadedTeam);
      loadedTeam.cowboy_info = action.payload.team.cowboy_info;
      return {
        ...state,
        loadedTeam: loadedTeam,
      };
    case ACTIONS.SCORE.GET_RANK_DETAILS:
      return {
        ...state,
        rankdetails: action.payload,
      };
    case ACTIONS.TEAM.CLEAR_DRAFT_TEAM_DATA:
      return {
        ...state,
        tempTeamDraftList: null,
      };
    case ACTIONS.TEAM.CLEAR_TEAMDATA:
      return {
        ...state,
        loadedTeam: null,
        paymentPlans: null,
      };
    case ACTIONS.TEAM.CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };
    default:
      return state;
  }
};

export default teamReducer;

import { any } from "prop-types";
import { ActionExtended } from "src/_common/interfaces/ActionExtended";
import { ACTIONS } from "src/_config";
export interface ScoreReducer {
  scores: any;
  rodeoResults: any;
  roundScore: any;
  curcuitScore: any;
  standingsData: any;
  leagueScores: any;
  roundLeagueScore: any;
  prizeScores: any;
  prizeRoundscores: any;
  topGameTabData: any;
}

const initialState: ScoreReducer = {
  scores: null,
  rodeoResults: null,
  roundScore: null,
  curcuitScore: null,
  standingsData: null,
  leagueScores: null,
  roundLeagueScore: null,
  prizeScores: null,
  prizeRoundscores: null,
  topGameTabData: null,
};

const scoreReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.SCORE.GET_GAME_SCORE:
      return {
        ...state,
        scores: action.payload,
      };
    case ACTIONS.SCORE.CLEAR_SCORE:
      return {
        ...state,
        scores: null,
      };
    case ACTIONS.SCORE.CLEAR_ROUND_SCORE:
      return {
        ...state,
        roundScore: null,
      };
    case ACTIONS.SCORE.CLEAR_LEAGUE_ROUND_SCORE:
      return {
        ...state,
        roundLeagueScore: null,
      };
    case ACTIONS.SCORE.GET_RODEO_RESULTS:
      return {
        ...state,
        rodeoResults: action.payload,
      };
    case ACTIONS.SCORE.ROUNDWISERESULTS:
      return {
        ...state,
        roundScore: action.payload,
      };
    case ACTIONS.SCORE.CURCUITRESULTS:
      return {
        ...state,
        curcuitScore: action.payload,
      };
    case ACTIONS.SCORE.LATESTSTANDINGS:
      return {
        ...state,
        standingsData: action.payload,
      };
    case ACTIONS.SCORE.LEAGUEOVERALLSCORE:
      return {
        ...state,
        leagueScores: action.payload,
      };
    case ACTIONS.SCORE.LEAGUEROUNDSCORE:
      return {
        ...state,
        roundLeagueScore: action.payload,
      };
    case ACTIONS.SCORE.PRIZEOVERALLSCORE:
      return {
        ...state,
        prizeScores: action.payload,
      };
    case ACTIONS.SCORE.PRIZEROUNDSCORE:
      return {
        ...state,
        prizeRoundscores: action.payload,
      };
    case ACTIONS.SCORE.TOPHEADERSCORE:
      return {
        ...state,
        topGameTabData: action.payload,
      };
    default:
      return state;
  }
};

export default scoreReducer;
